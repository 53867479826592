<template>
  <div class="main_frame">
	<img class="top_one_frame" :src="`${require('@/assets/imgs/home/top_one.png')}`"/>
	<img class="top_two_frame" :src="`${require('@/assets/imgs/home/top_two.png')}`"/>
	
	<div class="top_three_frame">
		<img class="top_three_img" :src="`${require('@/assets/imgs/home/top_three.png')}`"/>
	</div>
	
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "Home",
  data() {
    return {
      swiper_isshow: false,

      activeIndex: 0, // 当前 index

      swiperOption: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    mobile: function () {
      // let mobile = false;
      // let width = document.documentElement.clientWidth;
      // if (width <= 1000) {
      //   mobile = true;
      // }

      // // var isiOs = false;

      // // var isAndroid = false;

      // // var isWindowsPhone = false;

      // // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // //   isiOS = true;
      // // } else if (/(Android)/i.test(navigator.userAgent)) {
      // //   isAndroid = true;
      // // } else if (/(Windows Phone)/i.test(navigator.userAgent)) {
      // //   isWindowsPhone = true;
      // // }

      // return mobile;
      var userAgentInfo = navigator.userAgent;

      var mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];

      var mobile_flag = false;
      //根据userAgent判断是否是手机
      for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          mobile_flag = true;
          break;
        }
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;

      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
        mobile_flag = true;
      }

      return mobile_flag;
    },
  },
  methods: {
    onSlideChange(e) {
      this.activeIndex = e.realIndex;
    },
  },
};
</script>

<style>
	
	.main_frame{
		width: 100%;
		height: 100%;
		background-color: #F8F8F8;
	}
	
	.top_one_frame{
		width: 1941px;
		height: 1312px;
	}
	
	.top_two_frame{
		margin-top: 90px;
		width: 1584px;
		height: 1614px;
		
	}
	
	.top_three_frame{
		margin-top: 142px;
		width: 100%;
		height: 1195px;
		background: #FFFFFF;
		margin-bottom: 142px;
	}
	
	.top_three_img{
		margin-top: 103px;
		width: 1587px;
		height: 1010px;
	}
	
.indicator-box {
  position: absolute;
  top: 500px;
  left: 124px;
  z-index: 98;
}
.indicator-item {
  width: 80px;
  height: 12px;
  opacity: 0.6;
  background: #ffffff;
  border-radius: 6px;
  margin-right: 20px;
  transition: all 0.3s;
}
.indicator-item-normal {
  opacity: 0.6;
  background: #ffffff;
}
.indicator-item-active {
  opacity: 1;
  background: #319bff;
}

.body_box_item_img {
  width: 100%;
}
.body_box_item {
  width: 100%;
  position: relative;
}
/* .body_box {
  max-width: 1920px;
 } */
.swiper-box-bottom-item-img {
  width: 360px;
}
.swiper-box-bottom-item {
  transition: all 0.5s;
}
.swiper-box-bottom-item :hover {
  box-shadow: 0px 0px 9px 3px #999;
  transform: translate(-2px, -2px);
}
.swiper-box-bottom {
  padding: 0 8.3%;
  position: absolute;
  width: 100vw;
  bottom: -103px;
  z-index: 99;
}
.swiper-box {
  width: 100%;
  position: relative;
}
.banner-img {
  width: 100%;
}

.downLoadBtn {
  position: absolute;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  width: 20%;
  height: 8%;
  right: 6%;
  padding: 10px;
  border-radius: 25px;
}
@media screen and (max-width: 1024px) {
  .indicator-item {
    width: 10px;
    height: 10px;
  }
  .indicator-box {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    z-index: 99;
    display: none;
  }
  .indicator-item-normal {
    background-color: #319bff;
    opacity: 0.3;
  }
  .swiper-box-bottom {
    position: static;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .swiper-box-bottom-item {
    flex-shrink: 0;
    margin-bottom: 2.5px;
    padding: 2.5px;
    width: 50%;
  }
  .swiper-box-bottom-item-img {
    width: 100%;
  }
  .body_box_item_img {
    margin-bottom: 10px;
  }
  .downLoadBtn {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .downLoadBtn {
    padding: 12px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  .downLoadBtn {
    right: 4%;
    width: 25%;
    -webkit-transform: scale(0.8);
  }
}
</style>